import React from "react"
import Title from "./Title"
const Contact = () => {
  return (
    <footer className="section bg-white" id="contact">
      <div className="section-container">
        <Title title="Contáctame" />
        <article className="contact-form">
          <form action="https://formspree.io/f/mayvnkeg" method="POST">
            <div className="form-group">
              <div
                class="wrap-input validate-input"
                data-validate="Se rquiere télefono"
              >
                <input
                  type="text"
                  name="nombre"
                  placeholder="nombre"
                  className="form-control input"
                />
                <span class="focus-input"></span>
              </div>

              <div
                class="wrap-input validate-input"
                data-validate="Se rquiere télefono"
              >
                <input
                  type="text"
                  name="empresa"
                  placeholder="empresa"
                  className="form-control input"
                />
                <span class="focus-input"></span>
              </div>
            </div>
            <div className="form-group">
              <div
                class="wrap-input validate-input"
                data-validate="Se rquiere télefono"
              >
                <input
                  type="email"
                  name="email"
                  placeholder="email"
                  className="form-control input"
                />
                <span class="focus-input"></span>
              </div>
              <div
                class="wrap-input validate-input"
                data-validate="Se rquiere télefono"
              >
                <input
                  type="text"
                  name="telefono"
                  placeholder="teléfono"
                  className="form-control input"
                />
                <span class="focus-input"></span>
              </div>
            </div>
            <div className="form-group">
              <div
                class="wrap-textarea validate-input"
                data-validate="Se rquiere télefono"
              >
                <textarea
                  name="mensaje"
                  rows="5"
                  placeholder="mensaje"
                  className="form-control input"
                ></textarea>
                <span class="focus-input"></span>
              </div>
            </div>
            <button type="submit" className="btn center-btn">
              Enviar mensaje
            </button>
          </form>
        </article>
        <div className="footer-rights">
          <h5>
            &copy;{new Date().getFullYear()}
            <span> MadeByJona</span> todos los derechos reservados.
          </h5>
        </div>
      </div>
    </footer>
  )
}

export default Contact
