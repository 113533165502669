import React from "react"
import Seo from "../components/Seo"
import Contact from "../components/Contact"
import ReactFullpage from "@fullpage/react-fullpage"
import { FaEnvelopeSquare , FaWhatsapp, FaLinkedin, FaInstagram, FaBehance, } from "react-icons/fa"

const contact = () => {
  return (
    
    <ReactFullpage
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <>
          <Seo title="Contacto" />
          <ReactFullpage.Wrapper>
            <div className="section bg-grey">
              <ul className="contact-data">
              <li>
                  <a href="tel:+59894661419">  
                    <h2>
                      <FaWhatsapp /> +598 94 661 419
                    </h2>
                  </a>
                </li>
                <li>
                  <a href="mailto:hi@madebyjona.com">
                    <h2>
                      <FaEnvelopeSquare /> hi@madebyjona.com
                    </h2>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/made.by.jona">
                    <h2>
                      <FaInstagram /> @made.by.jona 
                    </h2>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/madebyjona">
                    <h2>
                      <FaLinkedin /> @madebyjona
                    </h2>
                  </a>
                </li>
                <li>
                  <a href="https://www.behance.net/madebyjona">
                    <h2>
                      <FaBehance /> @madebyjona 
                    </h2>
                  </a>
                </li>

              </ul>

            </div>
            <Contact className="section" />
          </ReactFullpage.Wrapper>
          </>
        )
      }}
    />
  )
}

export default contact
